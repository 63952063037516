<template>
  <div class="pop_container">
    <div class="title_area error">Booking ID that has status as Error</div>
    <div class="body_area">
      <div class="form_section_generate_slip">
      
          <div class="error_list">
            <div v-for="(error, i) in errors" :key="'error_' + i">
              <p class="font-weight-bold mb-2">{{ error.errors }}</p>
              <!-- <p class="error-desc" v-for="(suberrors, j) in error.errors" :key="'suberrors_' + i + j">
                {{ suberrors }}
              </p> -->
            </div>
          </div>

        <div class="pop_buttons_container">
          <a class="tr_cancel" @click="closeModal">Close</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "popErrorBookingidList",
  methods: {
    closeModal() {
       this.$emit("closeModal");
    }
  },
  props: ["errors"]
};
</script>
<style lang="scss">
.pop_container .title_area.error {
  
  text-align: left;
  padding: 0 10px;
}
.error_list {
  width: 100%;
  height: 200px;
  overflow-y: auto;
  padding: 10px;
  text-align: left;
  p {
    padding-bottom: 10px;
    color: red;
  }
}
</style>